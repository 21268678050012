import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { withStore } from "@spyna/react-store";

import { setAuthorizationToken } from "../utils";

import favicon from './public/favicon.ico';

class NavBar extends React.Component {
  constructor(props) {
    super(props);

    const projectId_props = Number(this.props.match.params.id);
    this.state = {
      projectId_state : projectId_props,
    }
  }
  
  handleLogout(e) {
    const { history } = this.props;

    axios({
      method: "delete",
      url: "/auth/logout",
    })
      .then(() => {
        // Clearing data on logout
        localStorage.removeItem("access_token");
        this.props.store.set("isUserLoggedIn", false);
        this.props.store.set("isAdmin", false);
        this.props.store.set("isLoading", false);
        this.props.store.set("username", ""); 
        //this.props.store.set("projectId", 1); 
        
        setAuthorizationToken(null);

        history.push("/");
      })
      .catch((error) => {
        // TODO: Show error logging out
        console.log(error);
      });
  }

  render() {
    const isUserLoggedIn = this.props.store.get("isUserLoggedIn");
    const isAdmin = this.props.store.get("isAdmin");
    const username = this.props.store.get("username");
    //const projectId = this.props.store.get("projectId");

    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <Link to="/" className="navbar-brand">
        <img src={favicon} alt="Audino Favicon" className="mr-2" />
          audino
        </Link>
        {isUserLoggedIn && (
          <div className="d-flex w-100">
            <div className="collapse navbar-collapse">
              <ul className="navbar-nav mr-auto">
                <li className={`nav-item`}>
                  <Link className="nav-link" to="/dashboard">
                    Projekte
                  </Link>
                </li>
                <li className={`nav-item`}>
                  <Link className="nav-link" to="/docs">
                    Anleitung
                  </Link>
                </li>
                {isAdmin && (
                  <li className={`nav-item`}>
                    <Link className="nav-link" to="/progress">
                      Progress
                    </Link>
                  </li>
                )}
                {isAdmin && (
                  <li className={`nav-item`}>
                    <Link className="nav-link" to="/reported">
                      Reported
                    </Link>
                  </li>
                )}
                {isAdmin && (
                  <li className={`nav-item`}>
                    <Link className="nav-link" to="/admin">
                      Admin Panel
                    </Link>
                  </li>
                )}
              </ul>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <button
                    type="button"
                    className="nav-link btn btn-link text-decoration-none"
                    onClick={(e) => this.handleLogout(e)}
                  >
                    Ausloggen
                  </button>
                </li>
                <li className="nav-item" >
                  <div className="text-light" id="navbarUsername" >
                    Willkommen,  {username}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        )}
      </nav>
    );
  }
}

export default withRouter(withStore(NavBar));
