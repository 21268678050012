import axios from "axios";
import React from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { withStore } from "@spyna/react-store";
import '../css/docs.css'; 
import '../css/progress.css'; 

class Progress extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isDataLoading: false,
      users: [],
      users_projects_data: [],
      mergedUserData: [],
      ready_data: [],
    };
  }  

  componentDidMount() {
    this.setState({ isDataLoading: true });

    axios({
      method: "get",
      url: `/api/progress`,
    })
      .then((response) => {
        this.setState({
          users_projects_data: response.data.users_projects_data,
          isDataLoading: false,
        });

      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        this.setState({
          errorMessage:"Error fetching progress",
          isDataLoading: false,
        });
      });

    axios({
      method: "get",
      url: "/api/users",
    })
      .then((response) => {
        this.setState({ users: response.data.users, isUserLoading: false });
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        this.setState({
          errorMessage: "Error fetching users",
          isUserLoading: false,
        });
      });  
  }

  render() {
    const {
      users,
      users_projects_data,
      isDataLoading 
    } = this.state;

    let mergedUserData = users_projects_data.map(userProject => {
      const userDetail = users.find(user => ( (user.user_id === userProject.user_id) && (user.username === userProject.username) ));
      return {
          ...userProject,
          role: userDetail ? userDetail.role : 'Unknown', // Default to 'Unknown' if not found
      };
    });

    return (
      <div className="documentation-container bottom-padding">
        <Helmet>
          <title>Progress</title>
        </Helmet>
        <div className="container h-100">
          <div className="h-100 mt-5">
            <div className="row border-bottom my-3">
              <div className="col float-left">
                <h1>Kartei aller Benutzer</h1>
              </div>
              <hr />
              {!isDataLoading ? (
                <table className="table table-striped text-center">
                  <thead>
                    <tr>
                      <th scope="col">User</th>
                      <th scope="col">Role</th>
                      <th scope="col">Projekt</th>
                      <th scope="col">Fertig</th>
                      <th scope="col">Audiodaten</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mergedUserData.flatMap(user => {
                      // Create an array from the user's projects.
                      return user.projects.map((project, index) => {
                          // For the first project, include the user and role spanning all project rows.
                          return (
                            <tr key={`${user.user_id}-${index}`}>
                              <td className="align-middle">{user.username}</td>
                              <td className="align-middle">{user.role}</td>
                              <td className="align-middle">{project.project_name}</td>
                              <td className="align-middle">{project.data_marked_for_review} / {project.data_count}</td>
                              <td className="align-middle">
                                {project.data_ready && project.data_ready.length > 0 ? (
                                    <div className="scrollable-list">
                                      {project.data_ready.map((dataItem, index) => (
                                        <div key={index}>
                                          <a href={`/projects/${project.project_id}/data/${dataItem[0]}/annotate`}>
                                            Audio ID: {dataItem[0]} - Audio Path: {dataItem[1]} {/* ToDo: path name as a name */}
                                          </a>
                                        </div>
                                      ))}
                                    </div>
                                  ) : (
                                    <p>Keine Daten verfügbar</p>
                                  )}
                              </td>

                            </tr>
                          );
                        })
                      })
                    }
                  </tbody>
                </table>    
              ) : null}
            </div>  
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStore(Progress));
