import React from 'react';
import EuregonLogoImg from '../doc_images/euregon-logo.svg';

const Footer = () => {
  return (
    <footer style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', backgroundColor: '#4f4f4f', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px', borderRight: '1px solid #ffffff', paddingRight: '20px' }}>
        <img src={EuregonLogoImg} alt="Euregon Logo" style={{ marginRight: '10px' }} />
        <p style={{ margin: 0, color: '#ffffff', fontWeight: 'bold' }}>2024. Euregon AG.</p>
      </div>
      <div style={{ paddingLeft: '20px', textAlign: "left", color: '#ffffff' }}>
        <p style={{ margin: 0 }}>Wir sind <span style={{ color: '#f0756b' }}>DER</span> Softwarepartner für die Pflege.</p>
        <p style={{ margin: 0 }}>Wir führen Pflege in die Zukunft.</p>
      </div>
    </footer>
  );
};

export default Footer;
