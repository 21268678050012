import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const noop = () => {};

const IconButton = ({ icon, size = "lg", title = "", onClick = noop }) => {
  return (
    <button
      type="button"
      className="btn btn-default"
      onClick={onClick}
      title={title}
    >
      <FontAwesomeIcon icon={icon} size={size} />
    </button>
  );
};

IconButton.propTypes = {
  icon: PropTypes.object.isRequired,
  size: PropTypes.oneOf(["lg", "sm", "2x"]),
};

const Button = ({
  text,
  type,
  title = "",
  size = "lg",
  isDisabled = false,
  onClick = noop,
  isSubmitting: showLoader = false,
  className = "", // Default prop for className
  arrowDirection = "" // Prop for arrow direction
}) => {
  const arrowIcon = arrowDirection === 'right' ? faArrowRight : (arrowDirection === 'left' ? faArrowLeft : null);
  return (
    <button
      type="button"
      className={`btn btn-${size} btn-${type} btn-block save-button-width ${className}`}
      disabled={isDisabled}
      onClick={onClick}
      title={title}
    >
      {arrowDirection === 'left' && arrowIcon && <FontAwesomeIcon icon={arrowIcon} className="mr-2" />}
      {text}
      {arrowDirection === 'right' && arrowIcon && <FontAwesomeIcon icon={arrowIcon} className="ml-2" />}
      {showLoader ? (
        <span
          className={`spinner-border ml-2 btn-loader--size-${size}`}
          role="status"
          aria-hidden="true"
        ></span>
      ) : null}
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["lg", "sm"]),
  type: PropTypes.oneOf(["primary", "danger", "rose", "info", "dark", "success", "warning", "amber", "cyan", "teal"]),
  showLoader: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isSubmitting: PropTypes.bool,
  arrowDirection: PropTypes.oneOf(["left", "right", ""]),
};

export { Button, IconButton };
