import axios from "axios";
import React from "react";
import WaveSurfer from "wavesurfer.js";
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.min.js";
import TimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { withStore } from "@spyna/react-store";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearchMinus,
  faSearchPlus,
  faBackward,
  faForward,
  faPlayCircle,
  faPauseCircle,
} from "@fortawesome/free-solid-svg-icons";
import Alert from "../components/alert";
import { IconButton, Button } from "../components/button";
import Loader from "../components/loader";
import '../css/annotate.css'; 
import '../css/buttons.css'; 

class Annotate extends React.Component {
 /* 
    Comment for future implementations, so that developer could understand the objects faster.

    0.  Response has two dictionaries:
        1. Labels -- !!! by Pavlo deprecated
        2. Data
          Here, we are interested in the key 'data'

    1.  selectedSegment has the following properties: 
        data : { segmentation_id, transcription, annotations}; 
        end; 
        start;
        element : {style, ..} - html markup
    
    2.  wavesurfer.regions.list is an array of Object of selectedSegments

    3.  Object.values(wavesurfer.regions.list) returns an array containing the values of the properties of the wavesurfer.regions.list object. 
  */
  constructor(props) {
    super(props);

    const projectId = Number(this.props.match.params.projectid);
    const dataId = Number(this.props.match.params.dataid);

    this.state = {
      isPlaying: false,
      projectId,
      dataId,
      dataUrl: `/api/projects/${projectId}/data/${dataId}`,
      segmentationUrl: `/api/projects/${projectId}/data/${dataId}/segmentations`,
      isDataLoading: false,
      wavesurfer: null,
      zoom: 100,
      referenceTranscription: null,
      isMarkedForReview: false,
      annotationStatus2: false,
      annotationStatus3: false,
      selectedSegment: null,
      isSegmentDeleting: false,
      isAudioReady: false,
      isNavigating: false,
      isNavigatingOverview: false,
      isNavigatingWeiter: false,
      isNavigatingPrevious: false,
      isNavigatingUnbearbeitete: false,
      errorMessage: null,
      successMessage: null,
      backgroundColor: null,
    };

    this.labelRef = {};
    this.transcription = null;
    this.is_transcripted = null;
  }

  componentDidMount() {
    //console.log("Component mounted with dataid:", this.props.match.params.dataid);
    //console.log("Mounted with props:", this.props);

    const { dataUrl } = this.state;
    this.setState({ isDataLoading: true });
    const wavesurfer = WaveSurfer.create({
      container: "#waveform",
      barWidth: 2,
      barHeight: 1,
      barGap: null,
      mediaControls: false,
      plugins: [
        RegionsPlugin.create(),
        TimelinePlugin.create({ container: "#timeline" }),
      ],
    });
    this.showSegmentTranscription(null);
    this.props.history.listen((location, action) => {
      wavesurfer.stop();
    });
    wavesurfer.on("ready", () => {
      wavesurfer.enableDragSelection({ color: "rgba(0, 102, 255, 0.3)" });
      this.colorRegions(wavesurfer); // Calling colorRegions when the waveform is ready
    });
    wavesurfer.on("region-in", (region) => {
      this.showSegmentTranscription(region);
    });
    wavesurfer.on("region-out", () => {
      this.showSegmentTranscription(null);
    });
    wavesurfer.on("region-play", (r) => {
      r.once("out", () => {
        wavesurfer.play(r.start);
        wavesurfer.pause();
      });
    });
    wavesurfer.on("region-click", (r, e) => {
      e.stopPropagation();
      this.setState({
        isPlaying: true,
        selectedSegment: r,
      });
      r.play();
    });
    wavesurfer.on("pause", (r, e) => {
      this.setState({ isPlaying: false });
    });

    axios({
      method: "get",
      url: dataUrl,
    })
    .then((response) => {
      this.setState({
        isDataLoading: false,
      });

      const {
        reference_transcription,
        is_marked_for_review,
        annotation_state,
        segmentations,
        filename,
        is_transcripted, // undefined at the start, because is_transcripted is connected to the segmentations, not the data. Will be redifined below
        author_user_email,
      } = response.data;
      
      // Converting annotation_state to a boolean if needed, assuming 2 means true
      const annotationStatus2 = annotation_state === 2;
      const annotationStatus3 = annotation_state === 3;
      //console.log("Response Data: ", response.data)

      // setting regions/segment values
      const regions = segmentations.map((segmentation) => {
        return {
          start: segmentation.start_time,
          end: segmentation.end_time,
          data: {
            segmentation_id: segmentation.segmentation_id,
            transcription: segmentation.transcription,
            annotations: segmentation.annotations,
            is_transcripted: segmentation.is_transcripted
          },
        };
      });

      this.setState({
        isDataLoading: false,
        referenceTranscription: reference_transcription,
        isMarkedForReview: is_marked_for_review, // state to control the audio readiness status checkbox
        annotationStatus2: annotationStatus2,   // state to control the audio status reported checkbox
        annotationStatus3: annotationStatus3,  // state to control the audio status delete checkbox
        filename,
        is_transcripted,
        author_user_email,
      });

      wavesurfer.load(`/audios/${filename}`);
      wavesurfer.drawBuffer();
      const { zoom } = this.state;
      wavesurfer.zoom(zoom);

      this.setState({ wavesurfer });
      this.loadRegions(regions);
    })
    .catch((error) => {
      console.log(error);
      this.setState({
        isDataLoading: false,
      });
    });      
  }

  /**
   * Handles component updates
   * 
   * @param {Object} prevProps 
   */
  componentDidUpdate(prevProps) {
    //console.log("Component updated with current and previous dataIds:", this.props.match.params.dataid, prevProps.match.params.dataid);
    //console.log("Updated with props:", this.props);
    if (this.props.match.params.dataid !== prevProps.match.params.dataid) {
      this.loadData(this.props.match.params.dataId);
    }
  }

  /**
   * Paints the segment's region during work and audio-editing.
   * If the audio is_transcripted - Dark Green.
   * If audio is beeing edited - Light Green.
   * 
   * @param {Object} region - Region that will be painted
   * @param {String} color - String that controls the value of the region
   */
  handleTranscriptedRegions(region, color) { 
    if (color === "CHANGED") {
      region.element.style.backgroundColor = 'rgba(194, 240, 196, 0.42)'; // lighter green
    }
    else {
      region.element.style.backgroundColor = 'rgba(4, 182, 88, 0.42)'; // darker greeen
    }
  }

  /**
   * Paints the segment's regions when loading the webpage. 
   * If the audio is_transcripted - Dark Green.
   * If audio is beeing edited - Light Green.
   * 
   * @param {Object} wavesurfer 
   */
  colorRegions(wavesurfer) {
    const regions = Object.values(wavesurfer.regions.list);
    //console.log("Coloring regions : ", regions);

    regions.forEach(region => {
      // Accessing region properties
      const { data } = region;
      // const { start, end } = region;
      //console.log(`Region start: ${start}, end: ${end}, data: ${JSON.stringify(data)}`);

      // Applying styling based on transcripted status
      if (data.is_transcripted) {
        this.handleTranscriptedRegions(region);
      } else {
        // Appling styling for non-transcripted regions if needed
        // element.style.backgroundColor = 'non-transcripted-color';
      }
    });
  }

  loadRegions(regions) {
    const { wavesurfer } = this.state;
    regions.forEach((region) => {
      wavesurfer.addRegion(region);
    });

    //console.log("Loaded regions / segments : ", wavesurfer.regions.list)
  }

  showSegmentTranscription(region) {
    this.segmentTranscription.textContent =
      (region && region.data.transcription) || " ";
  }

  handlePlay() {
    const { wavesurfer } = this.state;
    this.setState({ isPlaying: true });
    wavesurfer.play();
  }

  handlePause() {
    const { wavesurfer } = this.state;
    this.setState({ isPlaying: false });
    wavesurfer.pause();
  }

  handleForward() {
    const { wavesurfer } = this.state;
    wavesurfer.skipForward(5);
  }

  handleBackward() {
    const { wavesurfer } = this.state;
    wavesurfer.skipBackward(5);
  }

  handleZoom(e) {
    const { wavesurfer } = this.state;
    const zoom = Number(e.target.value);
    wavesurfer.zoom(zoom);
    this.setState({ zoom });
  }
  /**
   * 0 - Init
   * 1 = Audio Ready
   * 2 = Needs Revisions
   * 3 = File Unbrauchbar (Unrecognisable or Trash). Admin must check it
   * @param {Event} e - The event object associated with the click, used to prevent the default action.
   * @param {integer} status_id - one of the three statuses: ready, report, delete
   */
  handleAnnotationStatus(e, status_id) {

    let annotationStateValue;
    let successMessageToUser;
    const newAnnotationStatus = e.target.checked;

    // console.log("status_id: ", status_id)
    if (status_id == 2) {
      
      annotationStateValue = newAnnotationStatus ? 2 : 0; // Set to 0 if unchecked, 2 if checked
      successMessageToUser = newAnnotationStatus ? "Die Audio wurde gemeldet" : "Der Revisionsstand hat sich geändert"
    } else if (status_id == 3) {
      annotationStateValue = newAnnotationStatus ? 3 : 0; // Set to 0 if unchecked, 3 if checked
      successMessageToUser = newAnnotationStatus ? "Die Audio wird gelöscht" : "Der Löschstatus hat sich geändert"
    } else {
      this.setState({
        errorMessage: "Fehler beim Audiostatuswechsel",
        successMessage: null,
        successMessageToUser: "Fehler beim Audiostatuswechsel"
      });
    }
    // const annotationStateValue = newAnnotationStatus ? 2 : 0; // Set to 0 if unchecked, 2 if checked
    console.log("annotationStateValue : ", annotationStateValue)
    
    const { dataUrl } = this.state;
    this.setState({ isDataLoading: true });

    // Changing status in the database
    axios({
      method: "patch",
      url: dataUrl,
      data: {
        annotation_state: annotationStateValue, // 0 = Initial State, 2 = Needs Revision, 3 = Delete File, 
      },
    })
      .then((response) => {
        this.setState({
          isDataLoading: false,
          annotationStatus2: status_id == 2 ? newAnnotationStatus : false,
          annotationStatus3: status_id == 3 ? newAnnotationStatus : false,

          isMarkedForReview: false,

          errorMessage: null,
          successMessage: successMessageToUser,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isDataLoading: false,
          errorMessage: "Fehler beim melden der Audio",
          successMessage: null,
        });
      });

    console.log("Data was marked as that of need of revision");
  }
  
  /**
   * Saves audio data as ready for training 
   * 
   * @param {Event} e - The event object associated with the click, used to prevent the default action.
   */
  handleIsMarkedForReview(e) {
    const { dataUrl, wavesurfer } = this.state;
    const isMarkedForReview = e.target.checked;
    this.setState({ isDataLoading: true });

    //checking all other segments
    const regions = Object.values(wavesurfer.regions.list);

    // Checking if all regions are transcripted
    const allTranscripted = regions.every(region => region.data.is_transcripted);
    
    if (allTranscripted) {
      // All regions are transcripted
      axios({
        method: "patch",
        url: dataUrl,
        data: {
          is_marked_for_review: isMarkedForReview,
          annotation_state: 1,
        },
      })
        .then((response) => {
          this.setState({
            isDataLoading: false,
            isMarkedForReview: response.data.is_marked_for_review,
            annotationStatus2: false,
            annotationStatus3: false,
            errorMessage: null,
            successMessage: "Der Transkriptionsstatus wurde geändert",
          });
        })
        .catch((error) => {
          console.log(error.response.data.message, error);
          this.setState({
            isDataLoading: false,
            errorMessage: "Fehler beim Ändern des Transkriptionsstatus",
            successMessage: null,
          });
        });

      console.log("All segments were transcripted and saved");
    } else {
      // Some regions are not transcripted
      this.setState({
        isDataLoading: false,
        errorMessage: "Nicht alle Segmente wurden transkribiert und gespeichert",
        successMessage: null,
      });

      console.log("Not all segments were transcripted")
    }
  }

  /**
   * Deletes the segment
   */
  handleSegmentDelete() {
    const { wavesurfer, selectedSegment, segmentationUrl } = this.state;
    this.setState({ isSegmentDeleting: true }); // prevents user from clicking this button
    if (selectedSegment.data.segmentation_id) {
      axios({
        method: "delete",
        url: `${segmentationUrl}/${selectedSegment.data.segmentation_id}`,
      })
        .then((response) => {
          wavesurfer.regions.list[selectedSegment.id].remove();
          this.setState({
            selectedSegment: null,
            successMessage: "Das Segment wurde erfolgreich gelöscht",
            isSegmentDeleting: false,
          });
        })
        .catch((error) => {
          console.error("Error deleting segment:", error);
          this.setState({
            errorMessage: "Keine Rechte, das Segment zu löschen",
            isSegmentDeleting: false,
          });
        });
    } else {
      wavesurfer.regions.list[selectedSegment.id].remove();
      this.setState({
        selectedSegment: null,
        isSegmentDeleting: false,
      });
    }
  }

  /**
   * Saves all segments.
   * 
   * Name of the function is a legacy. This is the first function that were written long ago (in a galaxy far away)
   * 
   * @param {Event} e - The event object associated with the click, used to prevent the default action.
   */
  handleAudioReady(e){
    const {wavesurfer, segmentationUrl} = this.state;
    this.setState({ 
      isAudioReady: false, // button spinner
      successMessage: "Alle Segmente wurden gespeichert. Markieren Sie das Audio als fertig." 
    });
    
    //console.log("All following regions / segments were saved : ", wavesurfer.regions.list) 

    Object.values(wavesurfer.regions.list).map((segment) => { // array
      //console.log("handleAudioReady, segment: ", segment)
      let { start, end, id } = segment;
      let { transcription, annotations, segmentation_id } = segment.data;
      if (!segmentation_id) {
        axios({
          method: "post",
          url: segmentationUrl,
          data: {
            start,
            end,
            transcription,
            annotations,
            is_transcripted: true, // Setting is_transcribed to true
          },
        })
          .then(({data}) => {
            wavesurfer.regions.list[id].data.segmentation_id = data.segmentation_id;

            this.setState({
              isSegmentSaving: false,
              successMessage: "Alle Segmente wurden gespeichert. Markieren Sie das Audio als fertig.",
              errorMessage: null,
            });
            // Change segment color to dark green
            this.handleTranscriptedRegions(segment);
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              isSegmentSaving: false,
              errorMessage: "Fehler beim Speichern der Segmente",
              successMessage: null,
            });
          });
      } else {
        axios({
          method: "put",
          url: `${segmentationUrl}/${segmentation_id}`,
          data: {
            start,
            end,
            transcription,
            annotations,
            is_transcripted: true, // Setting is_transcribed to true
          },
        })
          .then((response) => {
            this.setState({
              isSegmentSaving: false,
              successMessage: "Alle Segmente wurden gespeichert. Markieren Sie das Audio als fertig.",
              errorMessage: null,

            });
            // Change segment color to dark green
            this.handleTranscriptedRegions(segment);
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              isSegmentSaving: false,
              errorMessage: "Fehler beim Speichern der Segmente",
              successMessage: null,
            });
          });
      }
      segment.data.is_transcripted =  true;
      console.log("Segment is saved and changed color.", segment);
      //return;
    });
  }

    /**
   * Saves the chosen segments.
   * 
   * @param {Event} e - The event object associated with the click, used to prevent the default action.
   */
  handleSegmentSave(e) {
    this.setState({ isSegmentSaving: true }); // prevent user from interacting with this button during execution
    const { selectedSegment, segmentationUrl} = this.state;
    const { start, end } = selectedSegment;
    const { transcription, annotations, segmentation_id = null, } = selectedSegment.data;

    //console.log("handleSegmentSave, selectedSegment: ", selectedSegment)

    if (segmentation_id === null) {
      axios({
        method: "post",
        url: segmentationUrl,
        data: {
          start,
          end,
          transcription,
          annotations,
          is_transcripted: true, // Setting is_transcripted to true
        },
      })
        .then((response) => {
          const { segmentation_id } = response.data;
          selectedSegment.data.segmentation_id = segmentation_id;
          this.setState({
            isSegmentSaving: false,
            selectedSegment,
            successMessage: "Segment gespeichert",
            errorMessage: null,
          });

          // Changing segment color to dark green
          this.handleTranscriptedRegions(selectedSegment);

          selectedSegment.data.is_transcripted =  true;
          console.log("Segment is saved and changed color.", selectedSegment)
        })
        .catch((error) => {
          console.error("Error saving segment:", error);
          this.setState({
            isSegmentSaving: false,
            errorMessage: "Fehler beim Speichern des Segments",
            successMessage: null,
          });
        });
    } else {
      axios({
        method: "put",
        url: `${segmentationUrl}/${segmentation_id}`,
        data: {
          start,
          end,
          transcription,
          annotations,
          is_transcripted: true, // Setting is_transcripted to true
        },
      })
        .then((response) => {
          this.setState({
            isSegmentSaving: false,
            successMessage: "Segment gespeichert",
            errorMessage: null,
          });
          // Changing segment color to dark green
          this.handleTranscriptedRegions(selectedSegment);

          selectedSegment.data.is_transcripted =  true;
          console.log("Segment is saved and changed color.", selectedSegment)
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isSegmentSaving: false,
            errorMessage: "Fehler beim Speichern des Segments",
            successMessage: null,
          });
        });
    }
  }

  /**
   * Handles segment editing state. Calls a function that changes segment's colour.
   * 
   * @param {Event} e - The event object associated with the click, used to prevent the default action.
   */
  handleTranscriptionChange(e) {
    const { selectedSegment } = this.state; 
    selectedSegment.data.transcription = e.target.value;
    this.setState({ selectedSegment });

    this.handleTranscriptedRegions(selectedSegment, "CHANGED")
  }

  handleAlertDismiss(e) {
    e.preventDefault();
    this.setState({
      successMessage: "",
      errorMessage: "",
    });
  }

  /**
   * handles navigation to the Audio data overview.
   * 
   * @param {Event} e - The event object associated with the click, used to prevent the default action.
   */
  handleToOverview(e) {
    //e.preventDefault(); // Preventing the default form submit behavior
    this.setState({ isNavigatingOverview: true });
    const { projectId } = this.state;
    this.props.history.push(`/projects/${projectId}/data/`);
  }


  /**
   * Handles navigation to the next data set based on the current user's position and selected criteria.
   * 
   * This function prevents the default event action, sets the state to indicate navigation is in progress,
   * and makes an API call to fetch the next data set. The function then navigates to the new data annotation page
   * if the API call is successful. It handles errors by logging them and updating the state with error messages.
   *
   * @param {Event} e - The event object associated with the click, used to prevent the default action.
   * @param {boolean} include_fertig - Determines if "ready" audio data should be included in the navigation. 
   *                                   True returns back all all data, except for thos, reported.
   *                                   False returns only not ready data.
   */
  handleOnwards(e, include_fertig) {
    e.preventDefault();
    // preventing user from interacting with the button during execution
    if (include_fertig) {
      this.setState({ isNavigatingWeiter: true });
    }
    else {
      this.setState({ isNavigatingUnbearbeitete: true });
    }
    const { projectId } = this.state
    
    axios.get(`/api/projects/${projectId}/next-data`, {
      params: { current_data_id: this.state.dataId, include_fertig:include_fertig  }
    })
    .then(response => {
      // Navigating to the new route
      this.setState({ isNavigatingWeiter: false, isNavigatingUnbearbeitete: false });
      this.props.history.push(`/projects/${1}/data/${response.data.data_id}/annotate`);
    })
    .catch(error => {
      this.setState({ isNavigatingWeiter: false, isNavigatingUnbearbeitete:false });
      console.error('Error fetching next data:', error);
      if (error.response && error.response.status === 404) {
        // Handle no more data available
        this.setState({ errorMessage: error.response.data.message }); // message = "Keine weiteren Daten zum Transkribieren"
      } else {
          // Handle general server error
          this.setState({ errorMessage: "Fehler beim Abrufen der Daten. Bitte versuchen Sie es später noch einmal." });
      }
    });
  }

    /**
   * Handles navigation to the previous data setthat the user visited.
   * 
   * @param {Event} e - The event object associated with the click, used to prevent the default action.
   * 
   */
  handleToPrevious(e){
    this.setState({ isNavigatingPrevious: true });
    //e.preventDefault(); // Preventing the default form submit behavior
    const { projectId } = this.state;

    axios.get(`/api/projects/${projectId}/previous-data`, {
      params: { current_data_id: this.state.dataId  }
    })
    .then(response => {
      // console.log("This is the response: ", response)
      // Navigating to the new route
      // console.log("Next audio is with ID: ", response.data.data_id)
      this.setState({ isNavigatingPrevious: false});
      this.props.history.push(`/projects/${1}/data/${response.data.data_id}/annotate`);
    })
    .catch(error => {
      this.setState({ isNavigatingPrevious: false});
      console.error('Error fetching previous data:', error);
      // Handle error
      this.setState({ errorMessage:  error.response.data.message });
      
    });
  }

   /**
   * Handles navigation to the next reported data. Only available to admin.
   *
   * @param {Event} e - The event object associated with the click, used to prevent the default action.
   * 
   */
  handleAdminToReported(e) {
    e.preventDefault();
    const { projectId } = this.state
    
    axios.get(`/api/projects/${projectId}/next-reported-data`, {
      params: { current_data_id: this.state.dataId  }
    })
    .then(response => {
      // Navigating to the new route
      this.setState({ isNavigatingWeiter: false });
      this.props.history.push(`/projects/${1}/data/${response.data.data_id}/annotate`);
    })
    .catch(error => {
      this.setState({ isNavigatingWeiter: false });
      console.error('Error fetching next data:', error);
      if (error.response && error.response.status === 404) {
        // Handle no more data available
        this.setState({ errorMessage: error.response.data.message }); // message = "Keine weiteren Daten zum Transkribieren"
      } else {
          // Handle general server error
          this.setState({ errorMessage: "Fehler beim Abrufen der Daten. Bitte versuchen Sie es später noch einmal." });
      }
    });
  }

  render() {
    const {
      zoom,
      isPlaying,
      isDataLoading,
      isMarkedForReview,
      annotationStatus2,
      annotationStatus3,
      referenceTranscription,
      selectedSegment,
      isSegmentDeleting,
      isSegmentSaving,
      isAudioReady,
      isNavigatingOverview,
      isNavigatingWeiter,
      isNavigatingPrevious,
      isNavigatingUnbearbeitete,
      errorMessage,
      successMessage,
      dataId,
      author_user_email,
      filename,
    } = this.state;
    const isAdmin = this.props.store.get("isAdmin");

    return (
      <div className="bottom-padding">
        <Helmet>
          <title>Annotate</title>
        </Helmet>
        <div className="container h-100">
          <div className="h-100 mt-5 text-center">
            {errorMessage ? (
              <Alert
                type="danger"
                message={errorMessage}
                onClose={(e) => this.handleAlertDismiss(e)}
              />
            ) : null}
            {successMessage ? (
              <Alert
                type="success"
                message={successMessage}
                onClose={(e) => this.handleAlertDismiss(e)}
              />
            ) : null}
            {isDataLoading ? <Loader /> : null}
          
            <div className="row justify-content-md-center my-4">
              <div ref={(el) => (this.segmentTranscription = el)} className="segment-transcription-header "></div>
              <div id="waveform"></div>
              <div id="timeline"></div>
            </div> 
          
            {!isDataLoading ? (
              <div>
                <div className="row justify-content-md-center my-4">
                  <div className="col-1">
                    <IconButton
                      icon={faBackward}
                      size="2x"
                      title="Rückwärts springen"
                      onClick={() => {
                        this.handleBackward();
                      }}
                    />
                  </div>
                  <div className="col-1">
                    {!isPlaying ? (
                      <IconButton
                        icon={faPlayCircle}
                        size="2x"
                        title="Play"
                        onClick={() => {
                          this.handlePlay();
                        }}
                      />
                    ) : null}
                    {isPlaying ? (
                      <IconButton
                        icon={faPauseCircle}
                        size="2x"
                        title="Pause"
                        onClick={() => {
                          this.handlePause();
                        }}
                      />
                    ) : null}
                  </div>
                  <div className="col-1">
                    <IconButton
                      icon={faForward}
                      size="2x"
                      title="Nach vorne springen"
                      onClick={() => {
                        this.handleForward();
                      }}
                    />
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-1">
                    <FontAwesomeIcon icon={faSearchMinus} title="Zoom out" />
                  </div>
                  <div className="col-2">
                    <input
                      type="range"
                      min="1"
                      max="200"
                      value={zoom}
                      onChange={(e) => this.handleZoom(e)}
                    />
                  </div>
                  <div className="col-1">
                    <FontAwesomeIcon icon={faSearchPlus} title="Zoom in" />
                  </div>
                </div>
                <div className="row justify-content-center my-4">
                  {referenceTranscription ? (
                    <div className="form-group col-md-12">
                      <label className="font-weight-bold">
                        Vollständige Audiodaten
                      </label>
                      <textarea
                        className="form-control"
                        rows="4"
                        cols="50"
                        disabled={true}
                        value={referenceTranscription}
                      ></textarea>
                    </div>
                  ) : null}
                </div>
                {selectedSegment ? (
                  <div>
                    { /* Transcription */ }
                    <div className="row justify-content-center my-4">
                      <div className="form-group  col-md-12">
                        <label className="font-weight-bold">
                          Segmenttranskription
                        </label>
                        <textarea
                          className="form-control"
                          rows="3"
                          cols="50"
                          value={
                            (selectedSegment &&
                              selectedSegment.data.transcription) ||
                            ""
                          }
                          onChange={(e) => this.handleTranscriptionChange(e)}
                          ref={(el) => (this.transcription = el)}
                        ></textarea>
                      </div>
                    </div>
                    { /* Delete, Save, Save All buttons */ }
                    <div className="row justify-content-center my-4">
                      <div className="col-2">
                        <Button
                          size="lg"
                          type="danger"
                          disabled={isSegmentDeleting}
                          isSubmitting={isSegmentDeleting}
                          onClick={(e) => this.handleSegmentDelete(e)}
                          text="Löschen"
                        />
                      </div>
                      <div className="col-2">
                        <Button
                          size="lg"
                          type="primary"
                          disabled={isSegmentSaving}
                          onClick={(e) => this.handleSegmentSave(e)}
                          isSubmitting={isSegmentSaving}
                          text="Speichern"
                        />
                      </div>
                      <div className="col-2">
                        <Button
                          size="lg"
                          type="success"
                          disabled={isAudioReady}
                          onClick={(e) => this.handleAudioReady(e)}
                          isSubmitting={isAudioReady}
                          text="Alles Speichern"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="row justify-content-center my-4">

                  {/* Mark audio as marked_for_review */}
                  <div className="col-2 d-flex align-items-center">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="isMarkedForReview"
                        value={true}
                        checked={isMarkedForReview}
                        onChange={(e) => this.handleIsMarkedForReview(e)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="isMarkedForReview"
                      >
                        Audio Fertig
                      </label>
                    </div>
                  </div>
                  {/* Set audio status as reported */}
                  <div className="col-2 d-flex align-items-center">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="annotationStatus"
                        value={true}
                        checked={annotationStatus2}
                        onChange={(e) => this.handleAnnotationStatus(e, 2)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="annotationStatus"
                      >
                        Audio melden
                      </label>
                    </div>
                  </div>
                    {/*  Set audio status as junk. Only accessible by Admin */}
                    {isAdmin &&  (
                      <div className="col-2  d-flex align-items-center">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="annotationStatus"
                            value={true}
                            checked={annotationStatus3}
                            onChange={(e) => this.handleAnnotationStatus(e, 3)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="annotationStatus"
                          >
                            Audio unbrauchbar
                          </label>
                        </div>
                      </div>
                    )}
                </div>

                <div className="row justify-content-center my-4">
                <div className="col-2 d-flex align-items-center">
                    <Button
                      size="lg"
                      type="info"
                      arrowDirection="left"
                      className="button-30 button-30-info"
                      disabled={isNavigatingPrevious}
                      isSubmitting={isNavigatingPrevious}
                      onClick={(e) => this.handleToPrevious(e)}
                      text="Zurück"
                    />
                  </div>
                  <div className="col-2 d-flex align-items-center">
                    <Button
                      size="lg"
                      type="warning"
                      className="button-30 button-30-warning"
                      disabled={isNavigatingOverview}
                      isSubmitting={isNavigatingOverview}
                      onClick={(e) => this.handleToOverview(e)}
                      text="Zur Übersicht"
                    />
                  </div>
                  <div className="col-2 d-flex align-items-center">
                    <Button
                      size="lg"
                      type="info"
                      className="button-30 button-30-teal"
                      disabled={isNavigatingUnbearbeitete}
                      isSubmitting={isNavigatingUnbearbeitete}
                      onClick={(e) => this.handleOnwards(e, false)}
                      text="Unbearbeitete"
                    />
                  </div>
                  {isAdmin &&  (
                    <div className="col-2 d-flex align-items-center">
                    <Button
                      size="lg"
                      type="warning"
                      className="button-30 button-30-amber"
                      disabled={isNavigatingWeiter}
                      isSubmitting={isNavigatingWeiter}
                      onClick={(e) => this.handleAdminToReported(e)}
                      text="Reported"
                    />
                  </div>
                  )}
                  <div className="col-2 d-flex align-items-center">
                    <Button
                      size="lg"
                      type="info"
                      className="button-30 button-30-info"
                      arrowDirection="right"
                      disabled={isNavigatingWeiter}
                      isSubmitting={isNavigatingWeiter}
                      onClick={(e) => this.handleOnwards(e, true)}
                      text="Weiter"
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          { /* Author and Audio Names */ }
          <div class = "row justify-content-center my-4">
            <span style={{ marginRight:"20px"}}><small>{dataId}</small></span>
            <span style={{ marginRight:"20px", marginLeft: "20px"}}><small>{filename}</small></span>
            <span style={{ marginLeft:"20px"}}><small>{author_user_email}</small></span>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStore(Annotate));
