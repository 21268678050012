import axios from "axios";
import React from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { withStore } from "@spyna/react-store";

import Loader from "../components/loader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";

class Data extends React.Component {
  constructor(props) {
    super(props);

    const projectId = Number(this.props.match.params.id);

    const { location } = this.props;
    const params = new URLSearchParams(location.search);

    this.state = {
      users: [],
      projectId,
      data: [],
      active: params.get("active") || "todo",
      page: params.get("page") || 1,
      count: {
        in_progress: 0,
        marked_review: 0,
        todo: 0
      },
      apiUrl: `/api/current_user/projects/${projectId}/data`,
      tabUrls: {
        in_progress: this.prepareUrl(projectId, 1, "in_progress"),
        marked_review: this.prepareUrl(projectId, 1, "marked_review"),
        todo: this.prepareUrl(projectId, 1, "todo"),
      },
      nextPage: null,
      prevPage: null,
      isDataLoading: false,
      assignedData: {}, // This map will hold the data ID as the key and the user as the value.
      sortOrder: "asc", // default ascending order
      selectedUser: null, // For bulk assignment
    };
  }

  prepareUrl(projectId, page, active) {
    return `/projects/${projectId}/data?page=${page}&active=${active}`;
  }

  componentDidMount() {
    this.setState({ isDataLoading: true });
    let { apiUrl, page, active } = this.state;
    apiUrl = `${apiUrl}?page=${page}&active=${active}`;

    axios({
      method: "get",
      url: apiUrl,
    })
      .then((response) => {
        const {
          data,
          count,
          active,
          page,
          next_page,
          prev_page,
        } = response.data;
        this.setState({
          data,
          count,
          active,
          page,
          nextPage: next_page,
          prevPage: prev_page,
          isDataLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          errorMessage: error.response.data.message,
          isDataLoading: false,
        });
      });

    axios({
      method: "get",
      url: "/api/users",
    })
      .then((response) => {
        this.setState({ users: response.data.users, isUserLoading: false });
      })
      .catch((error) => {
        this.setState({
          errorMessage: error.response.data.message,
          isUserLoading: false,
        });
      });
  }

  /**
   * Assigns data to the selected user.
   * 
   * @param {Event} e 
   * @param {Object} data - Audio data 
   * @param {Object} user - User assign the audio data to
   */
  handleDataAssigned(e, data, user) {
    // setting the states to highlight the data and users assignings
    this.setState (
      prevState => ({
      assignedData: {
        ...prevState.assignedData,
        [data["data_id"]]: user,
      },
    }));
    const { projectId } = this.state;

    axios({
      method: "put",
      url: `/api/current_user/projects/${projectId}/data`,
      data: {
          filename: data["original_filename"],
          assigned_user_id: user["user_id"]
      }
    })
    .then(response => {
        console.log("Data record updated successfully");
    })
    .catch(error => {
        console.error("Error assigning data record to user:", error);
    });
  }

  /**
   * Assigns all data on the page to the selected user.
   */
  handleAssignAllToUser() {
    const { data, selectedUser, projectId } = this.state;
    if (!selectedUser) return;

    data.forEach((dataItem) => {
      axios({
        method: "put",
        url: `/api/current_user/projects/${projectId}/data`,
        data: {
          filename: dataItem["original_filename"],
          assigned_user_id: selectedUser.user_id,
        },
      })
        .then((response) => {
          this.setState((prevState) => ({
            assignedData: {
              ...prevState.assignedData,
              [dataItem["data_id"]]: selectedUser,
            },
          }));
          console.log(`Assigned data ${dataItem["original_filename"]} to ${selectedUser.username}`);
        })
        .catch((error) => {
          console.error("Error assigning data record to user:", error);
        });
    });
  }

  /**
   * Sorts audio data by author email
   */
  toggleAuthorSortOrder() {
    const { data, sortOrder } = this.state;
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc"; // Toggle sort order

    const sortedData = [...data].sort((a, b) => {
      const authorA = a["author_id"].toLowerCase();
      const authorB = b["author_id"].toLowerCase();

      if (newSortOrder === "asc") {
        return authorA.localeCompare(authorB);
      } else {
        return authorB.localeCompare(authorA);
      }
    });

    this.setState({ data: sortedData, sortOrder: newSortOrder });
  }

  render() {
    const {
      users,
      projectId,
      isDataLoading,
      data,
      count,
      active,
      page,
      nextPage,
      prevPage,
      tabUrls,
      assignedData,
      sortOrder,
      selectedUser,
    } = this.state;

    const isAdmin = this.props.store.get("isAdmin");
    const username = this.props.store.get("username");

    const nextPageUrl = this.prepareUrl(projectId, nextPage, active);
    const prevPageUrl = this.prepareUrl(projectId, prevPage, active);

    return (
      <div className="bottom-padding">
        <Helmet>
          <title>Daten</title>
        </Helmet>
        <div className="container h-100">
          <div className="h-100 mt-5">
            <div className="row border-bottom my-3">
              {/* Title */}
              <div className="col float-left">
                <h1>Audio Daten</h1>
              </div>
            </div>

            {!isDataLoading ? (
              <div>
                {/* Three tables: ToDo, In Progress, Fertig */}
                <div className="col justify-content-left my-3">
                  <ul className="nav nav-pills nav-fill">
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          active === "todo" ? "active" : null
                        }`}
                        href={tabUrls["todo"]}
                      >
                        ToDo ({count["todo"]})
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          active === "in_progress" ? "active" : null
                        }`}
                        href={tabUrls["in_progress"]}
                      >
                        In Progress ({count["in_progress"]})
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          active === "marked_review" ? "active" : null
                        }`}
                        href={tabUrls["marked_review"]}
                      >
                        Fertig ({count["marked_review"]})
                      </a>
                    </li>
                  </ul>
                </div>

                {/* Table Content Head: Dataname, Author, Number of Segments, Date Created, Assign To */}
                {data.length > 0 ? (
                  <table className="table table-striped text-center">
                    <thead>
                      <tr>
                        <th scope="col">Dateiname</th>

                        <th scope="col">
                          Author
                          {/** Sorting button */}
                          <button
                            className="btn btn-link btn-sm ml-2"
                            onClick={this.toggleAuthorSortOrder.bind(this)}
                            style={{ padding: "0" }}
                            title="Nach Autor sortieren"
                          >
                            <FontAwesomeIcon
                              icon={
                                sortOrder === "asc"
                                  ? faSortUp
                                  : sortOrder === "desc"
                                  ? faSortDown
                                  : faSort
                              }
                            />
                          </button>
                        </th>

                        <th scope="col">Segmente</th>

                        <th scope="col">Erstellt am</th>
                              
                        {isAdmin && (
                          <th scope="col">
                            Zuweisen zu
                            <div className="dropdown d-inline-block">
                              <button
                                className="btn btn-secondary dropdown-toggle ml-2"
                                type="button"
                                id="bulkAssignDropdown"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                {selectedUser ? selectedUser.username : "Benutzer"}
                              </button>
                              <div className="dropdown-menu" aria-labelledby="bulkAssignDropdown">
                                {users.map((user, index) => (
                                  <a
                                    key={index}
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() => this.setState({ selectedUser: user })}
                                  >
                                    {user.username}
                                  </a>
                                ))}
                              </div>
                              {selectedUser && (
                                <button
                                  className="btn btn-primary ml-2"
                                  onClick={() => this.handleAssignAllToUser()}
                                  disabled={!selectedUser}
                                >
                                  Alle zuweisen
                                </button>
                              )}
                            </div>
                          </th>
                        )}
                      </tr>
                    </thead>

                    {/* Table Content Body: Dataname, Author, Number of Segments, Date Created, Assign To*/}
                    <tbody>
                      {data.map((dataItem, index) => {
                        const isAssigned = assignedData[dataItem["data_id"]];
                        const isBulkSelected = selectedUser && !isAssigned;

                        return (
                          <tr
                            key={index}
                            style={isAssigned || isBulkSelected ? { backgroundColor: "#ebd1ce" } : {}} // Highlight rows
                          >
                            {/* Data */}
                            <td className="align-middle">
                              <a href={`/projects/${projectId}/data/${dataItem["data_id"]}/annotate`}>
                                {dataItem["original_filename"]}
                              </a>
                            </td>

                            {/* Author */}
                            <td className="align-middle">{dataItem["author_id"]}</td>

                            {/* Number of Segments */}
                            <td className="align-middle">
                              {dataItem["transcripted_segmentations"]} / {dataItem["number_of_segmentations"]}
                            </td>

                            {/* Audio Created On */}
                            <td className="align-middle">{dataItem["created_on"]}</td>

                            {/* Assign To */}
                            {isAdmin && (
                              <td className="align-middle">
                                <div className="dropdown">
                                  <button
                                    className="btn btn-secondary dropdown-toggle user-dropdown-button"
                                    type="button"
                                    id="userDropdown"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    {isAssigned ? isAssigned.username : "Benutzer"}
                                  </button>
                                  <div className="dropdown-menu" aria-labelledby="userDropdown">
                                    {users.map((user, index) => (
                                      <a
                                        key={index}
                                        className="dropdown-item"
                                        href="#"
                                        onClick={(e) => this.handleDataAssigned(e, dataItem, user)}
                                      >
                                        {user.username}
                                        {user.username === username && <span> (Sie)</span>}
                                        {isAssigned && isAssigned.user_id === user.user_id && (
                                          <span style={{ color: "#f0756b", marginLeft: "3px", fontWeight: "bold" }}>
                                            &#10003;
                                          </span>
                                        )}
                                      </a>
                                    ))}
                                  </div>
                                </div>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : null}
              </div>
            ) : null}
          </div>

          {/* Placeholder for when the data is absent */}
          <div className="row my-4 justify-content-center align-items-center">
            {isDataLoading ? <Loader /> : null}
            {!isDataLoading && data.length === 0 ? (
              <div className="font-weight-bold">Es sind keine Daten vorhanden!</div>
            ) : null}
          </div>

          {/* Page navigation */}
          <div className="col-12 my-4 justify-content-center align-items-center text-center">
            {prevPage ? (
              <a className="col" href={prevPageUrl}>
                Vorherige
              </a>
            ) : null}

            {data.length !== 0 ? <span className="col">Seite : {page}</span> : null}
            {nextPage ? (
              <a className="col" href={nextPageUrl}>
                Nächste
              </a>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStore(Data));
