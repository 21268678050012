import axios from "axios";
import React from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { withStore } from "@spyna/react-store";
import '../css/reported.css'; 

class Reported extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isDataLoading: false,
      users: [],
      users_projects_data: [],
      mergedUserData: [],
    };
  }  

  componentDidMount() {
    this.setState({ isDataLoading: true });

    axios({
      method: "get",
      url: `/api/reported`,
    })
      .then((response) => {
        this.setState({
          users_projects_data: response.data.users_projects_data,
          isDataLoading: false,
        });

      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        this.setState({
          errorMessage:"Error fetching reported",
          isDataLoading: false,
        });
      });

    axios({
      method: "get",
      url: "/api/users",
    })
      .then((response) => {
        this.setState({ users: response.data.users, isUserLoading: false });
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        this.setState({
          errorMessage: "Error fetching users",
          isUserLoading: false,
        });
      });  
  }

  render() {
    const {
      users,
      users_projects_data,
      isDataLoading 
    } = this.state;

    let mergedUserData = users_projects_data.map(userProject => {
      const userDetail = users.find(user => ( (user.user_id === userProject.user_id) && (user.username === userProject.username) ));
      return {
          ...userProject,
          role: userDetail ? userDetail.role : 'Unknown', // Defaults to 'Unknown' if not found
      };
    });

    return (
      <div className="documentation-container bottom-padding">
        <Helmet>
          <title>Gemeldete Dateien</title>
        </Helmet>
        <div className="container h-100">
          <div className="h-100 mt-5">
            <div className="row border-bottom my-3">
              <div className="col float-left">
                <h1>Kartei problemhafter Audiodaten</h1>
              </div>
              <hr />
              {!isDataLoading ? (
                <table className="table table-striped text-center">
                  <thead>
                    <tr>
                      <th scope="col">User</th>
                      <th scope="col">Role</th>
                      <th scope="col">Projekt</th>
                      <th scope="col">Gemeldete Audiodaten</th>
                      <th scope="col">Gelöschte Audiodaten</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mergedUserData.flatMap(user => {
                      // Create an array from the user's projects.
                      return user.projects.map((project, index) => {
                          // For the first project, include the user and role spanning all project rows.
                          return (
                            <tr key={`${user.user_id}-${index}`}>
                              <td className="align-middle">{user.username}</td>
                              <td className="align-middle">{user.role}</td>
                              <td className="align-middle">{project.project_name}</td>
                              <td>
                                <div className="center-content">
                                    <p className="audio-number">{project.annotation_state_list.length} </p>
                                    {/* Showing audiofiles that were reported as incomprehensible */}
                                    <div className= "scrollable-list">
                                    <ul>
                                      {project.annotation_state_list.map((item, index) => (
                                        <li key={index}>
                                          <a href={`/projects/${project.project_id}/data/${item[1]}/annotate`}>
                                            {item[0]}
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="center-content">
                                  {/* Showing audiofiles that were reported as deleted */}
                                  <p className="audio-number">{project.delete_state_list.length} </p>

                                  <div className="scrollable-list">
                                    <ul>
                                      {project.delete_state_list.map((item, index) => (
                                        <li key={index}>
                                          <a href={`/projects/${project.project_id}/data/${item[1]}/annotate`}>
                                            {item[0]}
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      })
                    }
                  </tbody>
                </table>    
              ) : null}
            </div>  
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStore(Reported));
