import React from "react";
import { Helmet } from "react-helmet";
import '../css/docs.css'; 

// Importing images
import audinoDashboardImg from '../doc_images/audino_project_uebersicht.png'; 
import audinoDataDashboard from '../doc_images/audino_daten_uebersicht.png'; 
import audinoAnnotateImg from '../doc_images/audino_annotation_uebersicht_anfang.png'; 
import audinoAnnotateExplained from '../doc_images/audino_annotation_dashboard_kommentiert.png'; 


class Documentation extends React.Component {
  render() {
    return (
      <div className="bottom-padding">
        <Helmet>
          <title>Anleitung</title>
        </Helmet>
        <div className="container h-100">
          <div className="h-100 mt-5">
            <div class="row border-bottom my-3">
              <div class="col float-left">
                <h1>Arbeitsanleitung mit Audino</h1>
              </div>
            </div>
            <div className="content-section">
              <h2>Über Audino</h2>
              <div className="general-info-section">
                <p>
                  Audino ist ein Transkriptionstool für Audiodaten. <i>Transkribieren</i> ist ein Verb, das „übertragen“ oder „umwandeln“ bedeutet. 
                  Das heißt, das gesprochene Wort wird in geschriebenen Text umgewandelt.
                  Der Prozess des Transkribierens kann dabei langwierig sein.
                </p>
                <h4>Die Aufnahme muss Segment für Segment angehört und niedergeschrieben (transkribiert) werden.</h4>
                <p>
                  Der Administrator hat Ihnen schon ein Projekt und Audiodaten in diesem Projekt zugewiesen. Dieser Leitfaden ist kurz und soll einen kurzen Überblick geben. 
                  Für einen umfassenderen Leitfaden haben Sie eine Datei mit viel detaillierteren Erklärungen erhalten.
                </p>
                <p>Wenn Sie Fragen haben, zögern Sie bitte nicht, uns zu kontaktieren und schreiben Sie eine E-Mail an <a href="mailto:pavlo.mospan@euregon.de">Pavlo Mospan</a>.</p>

                <img src={audinoDashboardImg} alt="Audino Projekt Übersicht" className="audino-img" />
              </div>

              <hr></hr>
              <h2>Data</h2>
              <div className="data-section">
                <p>
                  Auf der Datenseite werden alle für Sie verfügbaren Audiodateien angezeigt. Sie sind wie folgt getrennt:
                </p>

                <img src={audinoDataDashboard} alt="Audino Daten Daschboard" className="audino-img" />

                <ul className="data-categories">
                  <li><b>ToDo</b> - Von hier aus beginnen Sie normalerweise mit der Arbeit. Hier sind Audios, die kein transkribiertes Segment haben.</li>
                  <li><b>In Progress</b> - Hier sind Audiodateien mit mindestens einem transkribierten Segment.</li>
                  <li><b>Fertig</b> - Die Audiobearbeitung ist abgeschlossen.</li>
                </ul>
                <p>
                Auf der Registerkarte „ToDo“ werden alle Audiodaten angezeigt, die Sie mit Transkriptionen versehen müssen. 
                Wählen Sie eine aus und Sie werden im Transkribierungs-Dashboard angezeigt.
                </p>
              </div>

              <hr></hr>
              <h2>Transkription</h2>
              <div className="transcription-section">
                <h5>Das Hauptziel ist zu prüfen ob die Transkription von jedem Segment mit der Audio stimmt.</h5>
                <p>
                  Mit dem Transkription Dashboard (Beispiele unten) können Benutzer die ihnen zugewiesenen Audiodatenpunkte mit Anmerkungen versehen. 
                  Jedes Audio wird als Wellenform dargestellt. Um ein bestimmtes Segment abzuspielen, klicken Sie mit der Maus darauf. 
                  Dadurch werden auch die Segmenttranskription sowie die mit diesem Segment verknüpften Beschriftungen geöffnet.
                </p>
                <img src={audinoAnnotateImg} alt="Audino Data Daschboard" className="audino-img" />
                <p>
                  Unterhalb der Wellenform befindet sich ein Bedienfeld zum Anhalten/Abspielen sowie zum Vor- und Zurückspulen von Audiodateien. 
                  Sie können den Zoom-Schieberegler (nicht Lupe-Symbol) auch zum Vergrößern und Verkleinern der Wellenform verwenden. 
                </p>
                <p>
                  Unterhalb des Bedienfelds wird die gesamte Datentranskription aller Segmenten angezeigt. Anschließend kommt die einzelnde ausgewählte Segmenttranskription. 
                </p>
                <h5>Wichtig!!</h5>
                <p>
                  Das Segment kann auch gelöscht, neu addiert oder geändert werden, indem die Ränder links und rechts verschoben werden. 
                  Wir bieten ber dies nie zu tun, das es zu den problemen während Modelltraining führt.
                </p>
              </div>
              <div className="transcription-section">
                <h4>Bearbeiten</h4>
                <p>
                  Klicken Sie auf das Segment. Hören Sie sich den Ton an und prüfen Sie, ob die bereitgestellte Transkription korrekt ist und mit dem Ton übereinstimmt.
                </p>
                <p>
                  Falls nicht, ändern Sie die Transkription. Beim Bearbeiten wird das ausgewählte Segment <b>hellgrün</b> hervorgehoben.
                </p>
                <img src={audinoAnnotateExplained} alt="Audino Transkribierungseinleitung" className="audino-img" />
              </div>
              <div className="transcription-section">
                <h4>Speichern</h4>
                <p>  
                  Nachdem Sie die Transkription bearbeitet haben <b>oder</b> wenn die Transkription von Anfang an korrekt war, 
                  klicken Sie auf die Schaltfläche <b>„Speichern“</b> und fahren Sie mit den anderen Segmenten fort.
                </p>
                <p>
                  Das gespeicherte Segment wird <b>dunkelgrün</b> hervorgehoben :
                </p>
              </div>
              <div className="transcription-section">
                <h4>Alle Segmente Bearbeiten und Speichern</h4>
                <p>
                  Die Benutzeroberfläche ermöglicht das Speichern jedes Segments sowie das Speichern aller Segmente insgesamt. 
                  Klicken Sie dazu auf die Schaltfläche <b>„Alle speichern“</b>, wenn Sie sicher sind, dass die Segmente korrekt transkribiert wurden.
                </p>
              </div>
              <div className="transcription-section">
                <h4>Audio melden</h4>
                <p>
                  Wenn Sie feststellen, dass die Audio Geräusch, Schwachsin oder Haluzination enthält, melden Sie diese Audio. 
                </p>
                <p>
                  Dies gilt auch für wann Sie den Ton, die Rede oder Wörte in Audioaufnahme verstehen.
                </p>
              </div>
              <div className="transcription-section">
                <h4>Ende der Transkription</h4>
                <p>
                  Um die Transkription zu beenden, wenn alle Segmente überprüft, bearbeitet und transkribiert sind, markieren Sie das Audio mit der Schaltfläche <b>„Fertig“</b>. 
                </p>
                <p>
                  Dies ist ein wesentlicher Schritt und darf nicht vermieden werden, da die Audioaufnahme nicht als erledigt betrachtet wird. 
                  Bitte beachten Sie, dass Sie das Audiodatum nicht auch als fertig markieren können, bevor Sie die Segmente überprüft und gespeichert haben. 
                  Das System lässt Sie das nicht zu.
                </p>
              </div>
              <div className="transcription-section">
                <h4>Quellen</h4>
                <p>
                  Es gibt zwei äußerst wichtige Ressourcen, die Sie lesen sollten. 
                    <ul>
                        <li>Das umfassendere Audino-Tutorial, das weitere Funktionen des Tools erläutert.</li>
                        <li>Sowie die Regeln zur Transkription.</li>
                    </ul> 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Documentation;
